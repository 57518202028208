<template>
  <div id="app" class="url" style="padding: 15px 0">
    <div>
      <el-row>
        <el-col :span="24" style="margin-bottom: 10px">
          <el-page-header @back="goBack" content="设备管理"> </el-page-header>
        </el-col>
      </el-row>
    </div>
    <div style="margin:10px">
        <el-button
          size="medium"
          type="success"
          @click="addVideoDevice()"
          >新增设备</el-button
        >
    </div>
    <div>
      <el-table
        border
        :data="
          deviceData.filter(
            (data) =>
              !search ||
              data.name.toLowerCase().includes(search.toLowerCase())
          )"
        style="width: 100%; padding-bottom: 40px"
      >
        <el-table-column prop="abbr" label="姓名" width="auto">
        </el-table-column>
        <el-table-column prop="did" label="设备号" width="150px">
        </el-table-column>
        <el-table-column prop="vid" label="警员号" width="150px">
        </el-table-column>
        <el-table-column prop="online" label="在线状态" width="150px">
            <template slot-scope="scope">
                <span>{{ scope.row.online == 1 ? "在线" : "离线" }}</span>
            </template>
        </el-table-column>
        <el-table-column align="center" width="auto">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="search"
              size="medium"
              placeholder="输入组织名称搜索"
            />
          </template>
          <template slot-scope="scope">
            <el-button
              size="medium"
              type="primary"
              >编辑</el-button
            >
             <el-button
              size="medium"
              type="danger"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-dialog title="添加组织" 
        @close="closeDiviceDialog('deviceForm')"
       :visible.sync="diviceDialog"
        width="50%">
        <el-form ref="deviceForm" :model="deviceForm" label-width="100px" :rules="companyRules">
          <el-row :gutter="12">
            <el-col :span="8">
              <el-form-item label="设备号" prop="devIdno">
                <el-input v-model="deviceForm.devIdno"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="所属组织" prop="companyName">
                <el-select v-model="deviceForm.companyName">
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="12">
            <el-col :span="6" >
              <el-form-item>
                <el-button type="primary" @click="submitForm('deviceForm')">保存</el-button>
                <el-button @click="closeDiviceDialog('deviceForm')">关闭</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import dataStorage from '@/../utils/dataStorage.js'
import BaseData from '@/assets/config/BaseData.js';
import axios from "axios";

export default {
  data() {
    return {
      diviceDialog:false,
      
      deviceForm:{
        jsession:dataStorage.getBaseData("videoJsession"),
        devIdno:"", // 设备号
        devType:11 ,  // 设备类型
        companyName:"",// 公司名称
        factoryType:0, //厂家类型
      },
      options:[], // 公司名
      companyRules:{
        name: [
          { required: true, message: '请填写权限名称', trigger: 'change' }
        ],
        cid: [
          { required: true, message: '请选择所属组织', trigger: 'change' }
        ],
      },
      deviceData: [],
      search: "",
    };
  },
  created() {
    this.showDevice()
    this.findCompany()
  },
  methods: {
    // 获取设备在线状态
    showDevice(){
      axios.get('http://39.97.231.64:8088/StandardApiAction_getDeviceOlStatus.action',{
        params:{
            jsession: dataStorage.getBaseData("videoJsession"),
        }
      }).then(res=>{
        // console.log(res);
        this.deviceData = res.data.onlines
        console.log(this.deviceData);
      })
    },
    findCompany() {
      axios
        .get( "http://" +  BaseData.videoIP + ":"+  BaseData.videoPorts + "/StandardApiAction_findCompany.action", {
          params: {
            jsession: dataStorage.getBaseData("videoJsession"),
          },
        })
        .then((res) => {
          console.log(res);
           this.options = res.data.infos
        });
    },
    addVideoDevice(){
        this.diviceDialog = true
    },
   
     submitForm(deviceForm) {
      this.$refs[deviceForm].validate((valid) => {
        if (valid) {
          console.log(this.deviceForm);
          // 新增组织接口 有id 为修改
          axios
          .get( "http://" +  BaseData.videoIP + ":"+  BaseData.videoPorts + "/StandardApiAction_addDevice.action", {
            params:this.deviceForm,
          })
          .then((res) => {
            this.$refs[deviceForm].resetFields()
            this.diviceDialog = false
            if (res.data.result == 0) {
              this.$message.success("操作成功")
            }else{
              this.$message.error("操作失败")
            }
          });
        }
      });
    },
    closeDiviceDialog(deviceForm){
      this.diviceDialog = false
      this.$refs[deviceForm].resetFields()
    },

    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },
    
  },
};
</script>

<style lang="scss" scope>
</style>
